import React from "react";
import { useTranslation } from "react-i18next";
import { callApi, declineInformed } from "../../services/apiService";
import Button from "../../components/buttons/button";

const Declined = ({ dataPostulation, setState }) => {
  const { t } = useTranslation();

  const onSubmit = () => {
    const submitData = {
      postulationId: parseInt(dataPostulation.postulationId),
    };
    callApi(
      () => declineInformed(submitData),
      () => {
        backForm();
        window.location.reload();
      }
    );
  };

  const backForm = () => {
    setState(0);
  };

  return (
    <>
      <h2 className="title">{t("form.postulationDeclined")}</h2>
      <p>
        {dataPostulation
          ? t("form.postulationAnswer1") +
            " " +
            dataPostulation.courseName +
            " " +
            t("form.postulationAnswer2")
          : ""}
      </p>
      <p>{t("form.postulationAnswer3")}</p>
      <div className="container-inputs">
        <Button
          size="btn-m"
          onClick={onSubmit}
          label={t("form.postulation.newPostulation")}
        />
      </div>
    </>
  );
};

export default Declined;
